<template>
  <div>
    <div class="options">
      <button class="btn btn-primary" @click="changeOptions(true)">
        Comprar a Laboratórios
      </button>
      <button class="btn btn-info" @click="changeOptions(false)">
        Ver Compras
      </button>
    </div>
    <span v-if="buyBrand == true">
      <h1>Compras aos Laboratórios</h1>
      <span v-if="brandsPurchase.length == 0"> <h1>Não há Pedidos</h1></span>
      <div v-for="brand of brandsPurchase" :key="brand.brand">
        <div class="card">
          <h5 class="card-header">
            <b>{{ brand.lab }}</b>
          </h5>
          <div class="card-body">
            <table class="table table-sm table-bordered">
              <thead>
                <tr>
                  <th scope="col" style="width: 10%">Data</th>
                  <th scope="col" style="width: 15%">CNP</th>
                  <th scope="col">Produto</th>
                  <th scope="col" style="width: 7%">Quantidade Pedida</th>
                  <th scope="col" style="width: 7%">Cancelar Pedido</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="prod of brand.products" :key="prod.id_prod">
                  <td class="align-middle">
                    <span
                      v-if="
                        calculateDifferenceBetweenDates(prod.date_request) >= 3
                      "
                      style="background: red; color: white"
                    >
                      ⚠️ {{ splitDate2(prod.date_request) }}
                    </span>
                    <span v-else>
                      {{ splitDate2(prod.date_request) }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span v-for="ean of prod.prod_sku" :key="ean"
                      >{{ ean }} <br
                    /></span>
                    <br />
                  </td>
                  <td class="align-middle">{{ prod.name }}</td>
                  <td class="align-middle">
                    <span>
                      <b
                        >{{ prod.qty_missing }}
                        <a @click="openEditQtyModal(prod.qty_missing, prod.id)"
                          >✏️</a
                        ></b
                      ></span
                    >
                  </td>
                  <td class="align-middle">
                    <button
                      class="btn btn-danger btn-sm"
                      @click="cancelRequest(prod.id)"
                    >
                      Cancelar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </span>
    <span v-else>
      <h1>Lista de Compras Efetuadas</h1>
      <div class="row filter">
        <div class="col-sm-3">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Estado</span
              >
            </div>
            <select
              class="custom-select"
              id="inputGroupSelect01"
              v-model="statusSearch"
              @change="filterPurchases"
            >
              <option value="requested">Pedidas</option>
              <option value="completed">Completa</option>
              <option value="cancelled">Canceladas</option>
              <option value="late">Atrasado</option>
              <option value="all">Todas</option>
            </select>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >SKU</span
              >
            </div>
            <input
              type="text"
              class="form-control"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              v-model="skuFilter"
              @change="filterPurchases"
            />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm"
                >Laboratório</span
              >
            </div>
            <v-select
              :options="labs"
              v-model="labFilter"
              label="name"
              style="width: 70%"
              @input="filterPurchases()"
            ></v-select>
          </div>
        </div>
        <!-- <div class="col">
          <button class="btn btn-primary" @click="filterPurchases">
            🔎 Pesquisar
          </button>
        </div> -->
      </div>
      <b-table
        striped
        hover
        :items="danielaPurchase"
        :fields="fields"
        thead-class="text-white"
        small
      >
        <template #cell(sku)="data">
          <span v-for="prod of data.item.sku" :key="prod">
            <p>{{ prod }}</p>
          </span>
        </template>
        <template #cell(status)="data">
          <span v-if="data.item.status == 'requested'" class="requested">
            Pedido</span
          >
          <span v-else-if="data.item.status == 'completed'" class="completed">
            Completo</span
          >
          <span v-else-if="data.item.status == 'cancelled'" class="canceled">
            Anulado</span
          >
          <span v-else-if="data.item.status == 'late'" class="late">
            Atrasado</span
          >
        </template>
        <template #cell(purchase_date)="data">
          {{ splitDate(data.item.purchase_date) }}
        </template>
        <template #cell(to_farma)="data">
          <span
            >Alvim: <b>{{ data.item.to_alvim }}</b></span
          >
          <br />
          <span
            >Martins: <b>{{ data.item.to_martins }}</b></span
          >
          <br />
          <span
            >Gomes: <b>{{ data.item.to_gomes }}</b></span
          >
          <br />
          <span
            >Perelhal: <b>{{ data.item.to_perelhal }}</b></span
          >
        </template>
        <template #cell(actions)="data">
          <button
            v-if="
              data.item.status == 'requested' &&
              data.item.supplier != 'extraMarcas'
            "
            class="btn btn-primary btn-sm"
            @click="openModalEdit(data.item)"
          >
            Editar Pedido
          </button>
        </template>
        <template #cell(date_last_email)="data">
          {{ splitDate2(data.item.date_last_email) }}
        </template>
        <template #cell(late)="data">
          <div>
            <button
              v-if="
                data.item.status == 'requested' &&
                data.item.supplier != 'extraMarcas'
              "
              class="btn btn-warning btn-sm"
              @click="lateRequest(data.item.id_trans, data.item.id)"
            >
              Encomenda Atrasada
            </button>
          </div>
          <div style="margin-top: 5px">
            <button
              v-if="
                data.item.supplier != 'extraMarcas' &&
                (data.item.status == 'late' || data.item.status == 'requested')
              "
              class="btn btn-info btn-sm"
              @click="confirmRequest(data.item.id_trans, data.item.id)"
            >
              Concluída
            </button>
            <h5 v-if="data.item.supplier == 'extraMarcas' &&
                (data.item.status == 'late' || data.item.status == 'requested')">Pedido da Daniela</h5>
          </div>
        </template>
      </b-table>
    </span>
    <b-modal
      ref="modal_edit_request"
      id="modal_edit_request"
      title="Associar Produto"
      hide-footer
    >
      <form v-on:submit.prevent="editRequest()">
        <div>
          <label for="sku">SKU</label>
          <input
            type="text"
            class="form-control"
            id="sku"
            v-model="skuEdit"
            disabled
          />
        </div>
        <div>
          <label for="name">Nome</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="nameEdit"
            disabled
          />
        </div>
        <div>
          <label for="lab">Laboratório</label>
          <input
            type="text"
            class="form-control"
            id="lab"
            v-model="labEdit"
            disabled
          />
        </div>
        <div>
          <label for="qty_order">Quantidade Pedida</label>
          <input
            type="number"
            class="form-control"
            id="qty_order"
            v-model="qty_orderEdit"
            disabled
          />
        </div>
        <div>
          <label for="to_farma">Nova Quantidade</label>
          <input
            type="number"
            class="form-control"
            id="to_farma"
            v-model="newQtyOrder"
            @change="calcNewQty()"
          />
        </div>
        <div>
          <label for="to_farma">Para Alvim:</label>
          <input
            type="number"
            class="form-control"
            id="to_farma"
            v-model="to_alvimEdit"
            :disabled="newQtyOrder == 0"
            @change="calcNewQty()"
          />
        </div>
        <div>
          <label for="to_farma">Para Martins:</label>
          <input
            type="number"
            class="form-control"
            id="to_farma"
            v-model="to_martinsEdit"
            :disabled="newQtyOrder == 0"
            @change="calcNewQty()"
          />
        </div>
        <div>
          <label for="to_farma">Para Gomes:</label>
          <input
            type="number"
            class="form-control"
            id="to_farma"
            v-model="to_gomesEdit"
            :disabled="newQtyOrder == 0"
            @change="calcNewQty()"
          />
        </div>
        <div>
          <label for="to_farma">Para Perelhal:</label>
          <input
            type="number"
            class="form-control"
            id="to_farma"
            v-model="to_perelhalEdit"
            :disabled="newQtyOrder == 0"
            @change="calcNewQty()"
          />
        </div>
        <div>
          <label for="to_idivia">Para Idivia</label>
          <input
            type="number"
            class="form-control"
            :id="
              to_idiviaEdit >= idiviaRequest ? 'valid-input' : 'invalid-input'
            "
            v-model="to_idiviaEdit"
            disabled
          />
          <small id="to_idivia" class="form-text text-muted"
            >Idivia pediu <b>{{ idiviaRequest }} unidades</b></small
          >
        </div>
        <button class="btn btn-primary" type="submit">Editar</button>
      </form>
    </b-modal>
    <b-modal
      ref="modal_edit_qty"
      id="modal_edit_qty"
      title="Associar Produto"
      hide-footer
    >
      <form v-on:submit.prevent="editQtyRequest()">
        <div>
          <label for="qty">Nova Quantidade</label>
          <input type="numeric" class="form-control" v-model="newQty" />
        </div>
        <div style="margin-top: 10px; text-align: center">
          <button class="btn btn-primary" type="submit">Atualizar</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      brandsPurchase: [],
      labs: [],
      danielaPurchase: [],
      labFilter: "",
      skuFilter: "",
      statusSearch: "requested",
      buyBrand: true,
      skuEdit: "",
      nameEdit: "",
      labEdit: "",
      qty_orderEdit: "",
      to_farmaEdit: "",
      to_idiviaEdit: "",
      to_alvimEdit: "",
      to_martinsEdit: "",
      to_gomesEdit: "",
      to_perelhalEdit: "",
      idiviaRequest: 0,
      idEdit: "",
      idTransactionEdit: "",
      newQty: 0,
      newQtyOrder: 0,
      fields: [
        {
          key: "sku",
          label: "CNP",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "status",
          label: "Estado",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
          sortable: true,
        },
        {
          key: "purchase_date",
          label: "Data do Pedido",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
          sortable: true,
        },
        {
          key: "name",
          label: "Nome",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "lab",
          label: "Lab",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
          sortable: true,
        },
        {
          key: "qty_order",
          label: "Quantidade Pedida",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "to_farma",
          label: "Para Farmas",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
        },
        {
          key: "to_idivia",
          label: "Para Idivia",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
        },
        {
          key: "date_last_email",
          label: "Data do Último Email",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
          sortable: true,
        },
        {
          key: "actions",
          label: "Ações",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
        },
        {
          key: "late",
          label: "Atraso",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px; background-color:rgb(255, 147, 147); height:50px; vertical-align: middle",
        },
      ],
    };
  },
  methods: {
    async allBrandsPurchase() {
      try {
        await this.$store.dispatch("setAllBrandsPurchase");
        this.brandsPurchase = this.getAllBrandsPurchase;
        console.log("PURCHASES:", this.brandsPurchase);
      } catch (err) {
        console.log(err);
      }
    },
    async getAllLabs() {
      try {
        await this.$store.dispatch("allLaboratories");
        this.labs = this.getAllLaboratories;
        console.log(this.labs);
      } catch (err) {
        console.log(err);
      }
    },
    async filterPurchases() {
      console.log(this.labFilter);
      let labName;
      if (this.labFilter == null) labName = "";
      else labName = this.labFilter.name;
      try {
        await this.$store.dispatch("filterLabsPurchases", {
          lab: labName,
          status: this.statusSearch,
          sku: this.skuFilter,
        });
        this.danielaPurchase = this.getAllDanielaPurchase;
        console.log(this.danielaPurchase);
      } catch (err) {
        console.log(err);
      }
    },
    splitDate(date) {
      let split = date.split("T");
      let split2 = split[1].split(".");
      return split[0] + " " + split2[0];
    },
    splitDate2(date) {
      console.log(date);
      let split = date.split("T");
      //console.log(split[0]);
      return split[0];
    },
    changeOptions(value) {
      if (value == false) this.filterPurchases();
      this.buyBrand = value;
    },
    calculateDifferenceBetweenDates(date) {
      let dateNow = new Date();
      let year = dateNow.getFullYear();
      let month = ("0" + (dateNow.getMonth() + 1)).slice(-2);
      let day = ("0" + dateNow.getDate()).slice(-2);
      let finalDateNow = `${year}-${month}-${day}`;
      let orderDate = this.splitDate2(date);
      /* USAR BIBLIOTECA diff PARA COMPARAR AS DATAS */
      let diff = moment(finalDateNow).diff(orderDate, "days");
      return Number(diff);
    },
    async lateRequest(id_trans, id_daniela) {
      console.log(id_trans, id_daniela);
      this.$swal({
        title: "Tem a certeza que a encomenda está atrasada?",
        text: "A encomenda será marcada como atrasada",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, marcar como atrasada",
      }).then(async (willDelete) => {
        if (willDelete) {
          try {
            await this.$store.dispatch("lateLabPurchases", {
              id_trans: id_trans,
              id_daniela: id_daniela,
            });
            this.$swal({
              toast: true,
              position: "top-end",
              title: "Sucesso!",
              text: "Pedido cancelado com sucesso!",
              icon: "success",
              timer: 1500,
            });
            await this.filterPurchases();
          } catch (err) {
            alert(err);
            console.log(err);
          }
        }
      });
    },
    async openModalEdit(data) {
      this.skuEdit = data.sku[0];
      this.nameEdit = data.name;
      this.labEdit = data.lab;
      this.qty_orderEdit = data.qty_order;
      this.to_farmaEdit = data.to_farma;
      this.to_idiviaEdit = data.to_idivia;
      this.to_alvimEdit = data.to_alvim;
      this.to_martinsEdit = data.to_martins;
      this.to_gomesEdit = data.to_gomes;
      this.to_perelhalEdit = data.to_perelhal;
      this.idiviaRequest = data.idivia_request;
      this.idEdit = data.id;
      this.idTransactionEdit = data.id_trans;
      this.$refs["modal_edit_request"].show();
    },
    async calcNewQty() {
      if (this.newQtyOrder == 0) {
        this.to_idiviaEdit =
          Number(this.qty_orderEdit) -
          Number(this.to_alvimEdit) -
          Number(this.to_martinsEdit) -
          Number(this.to_gomesEdit) -
          Number(this.to_perelhalEdit);
      } else {
        let newIdivia =
          Number(this.newQtyOrder) -
          Number(this.to_alvimEdit) -
          Number(this.to_martinsEdit) -
          Number(this.to_gomesEdit) -
          Number(this.to_perelhalEdit);
        this.to_idiviaEdit = newIdivia;
      }
    },
    async editRequest() {
      this.to_alvimEdit = this.to_alvimEdit == "" ? 0 : this.to_alvimEdit;
      this.to_martinsEdit = this.to_martinsEdit == "" ? 0 : this.to_martinsEdit;
      this.to_gomesEdit = this.to_gomesEdit == "" ? 0 : this.to_gomesEdit;
      this.to_perelhalEdit =
        this.to_perelhalEdit == "" ? 0 : this.to_perelhalEdit;
      this.to_idiviaEdit = this.to_idiviaEdit == "" ? 0 : this.to_idiviaEdit;
      this.newQtyOrder = this.newQtyOrder == "" ? 0 : this.newQtyOrder;
      if (this.newQtyOrder == 0) {
        this.$swal({
          icon: "question",
          title: `Este pedido ao laboratório será cancelado!`,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, cancelar!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch("updateLabPurchase", {
                id: this.idEdit,
                newQty: this.newQtyOrder,
                idTransaction: this.idTransactionEdit,
              });
              this.$swal({
                toast: true,
                position: "top-end",
                title: "Sucesso!",
                text: "Pedido cancelado com sucesso!",
                icon: "success",
                timer: 1500,
              });
              await this.filterPurchases();
              this.$refs["modal_edit_request"].hide();
            } catch (err) {
              alert(err);
            }
          }
        });
      } else {
        this.$swal({
          icon: "question",
          title: `Este pedido ao laboratório será editado!`,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, editar!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch("updateLabPurchase", {
                sku: this.skuEdit,
                newQty: this.newQtyOrder,
                to_alvim: this.to_alvimEdit,
                to_martins: this.to_martinsEdit,
                to_gomes: this.to_gomesEdit,
                to_perelhal: this.to_perelhalEdit,
                to_idivia: this.to_idiviaEdit,
                id: this.idEdit,
                idTransaction: this.idTransactionEdit,
              });
              this.$swal({
                toast: true,
                position: "top-end",
                title: "Sucesso!",
                text: "Pedido editado com sucesso!",
                icon: "success",
                timer: 1500,
              });
              await this.filterPurchases();
              this.$refs["modal_edit_request"].hide();
            } catch (err) {
              alert(err);
            }
          }
        });
      }
    },
    async confirmRequest(id_trans, id_daniela) {
      this.$swal({
        title: "Tem a certeza que a encomenda este pedido foi entregue?",
        text: "Após confirmar o pedido será marcado como completo",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, confirmar!",
      }).then(async (willDelete) => {
        if (willDelete) {
          try {
            await this.$store.dispatch("confirmLabPurchase", {
              id_trans: id_trans,
              id_daniela: id_daniela,
            });
            this.$swal({
              toast: true,
              position: "top-end",
              title: "Sucesso!",
              text: "Pedido cancelado com sucesso!",
              icon: "success",
              timer: 1500,
            });
            await this.filterPurchases();
          } catch (err) {
            alert(err);
            console.log(err);
          }
        }
      });
    },
    async openEditQtyModal(qty, id) {
      this.newQty = qty;
      this.idEdit = id;
      this.$refs["modal_edit_qty"].show();
    },
    async editQtyRequest() {
      try {
        await this.$store.dispatch("editRequestQuantity", {
          id_trans: this.idEdit,
          newQty: this.newQty,
        });
        this.$swal({
          toast: true,
          position: "top-end",
          title: "Sucesso!",
          text: "Quantidade editada com sucesso!",
          icon: "success",
          timer: 1500,
        });
        await this.allBrandsPurchase();
        this.$refs["modal_edit_qty"].hide();
      } catch (err) {
        alert(err);
      }
    },
    async cancelRequest(id){
      this.$swal({
        title: "Tem a certeza que quer cancelar a encomenda?",
        text: "Após confirmar o pedido será marcado como cancelado",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, cancelar!",
      }).then(async (willDelete) => {
        if (willDelete) {
          try {
            await this.$store.dispatch("cancelBrandsPurchase", {
              id: id,
            });
            this.$swal({
              toast: true,
              position: "top-end",
              title: "Sucesso!",
              text: "Pedido cancelado com sucesso!",
              icon: "success",
              timer: 1500,
            });
            await this.allBrandsPurchase();
          } catch (err) {
            alert(err);
            console.log(err);
          }
        }
      });
    },
  },
  created() {
    this.allBrandsPurchase();
    this.filterPurchases();
    this.getAllLabs();
  },
  computed: {
    ...mapGetters(["getAllBrandsPurchase"]),
    ...mapGetters(["getProduct"]),
    ...mapGetters(["getAllDanielaPurchase"]),
    ...mapGetters(["getAllLaboratories"]),
  },
};
</script>

<style scoped>
.options {
  text-align: center;
  margin: 15px 0px 15px 0px;
}
.filter {
  padding: 30px;
}

.canceled {
  background-color: #b5182a;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}

.requested {
  background-color: #f0ad4e;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}

.completed {
  background-color: #5cb85c;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}
.late {
  background-color: #4f8dd9;
  padding: 6px;
  border-radius: 8px;
  color: white;
  width: 100%;
}
.table {
  text-align: center;
}
h1 {
  text-align: center;
}

.card {
  margin: 15px 15px;
}
.btn {
  margin: 0px 5px;
}
</style>
